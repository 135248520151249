import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header'
import "../css/HowItWorksDetails.css"
import { domain } from "./Domain";
import useFetch from "./useFetch";

const HowItWorksDetails = () => {
    let { name } = useParams();
    const navigate = useNavigate();
    const [currentVideo, setCurrentVideo] = useState(null);
    const { data, loading, setLoading } = useFetch(`${domain}/how-it-works`)
    const [durations, setDurations] = useState({});

    useEffect(() => {
        if (!loading && data.length > 0) {
            // Find the video that matches the name parameter
            const matchedVideo = data.find(video => video.slug === name);
            // If a match is found, set it as the current video; otherwise, set the first video as default
            setCurrentVideo(matchedVideo || data[0]);
        }
    }, [loading, data, name]);

    useEffect(() => {
        if (!loading && data.length > 0) {
            data.forEach(video => {
                getVideoDuration(video.video).then(duration => {
                    setDurations(prevDurations => ({
                        ...prevDurations,
                        [video.slug]: duration,
                    }));
                });
            });
        }
    }, [loading, data]);

    const getVideoDuration = (videoUrl) => {
        return new Promise((resolve) => {
            const video = document.createElement('video');
            video.src = domain + videoUrl;
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                resolve(formatDuration(video.duration));
            };
            video.onerror = () => {
                resolve("N/A"); // Handle error by setting duration as "N/A"
            };
        });
    };

    const formatDuration = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = Math.floor(duration % 60);

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) {
            formattedDuration += `${minutes}m `;
        }
        formattedDuration += `${seconds}s`;

        return formattedDuration.trim();
    };


    const handleVideoChange = (video) => {
        setCurrentVideo(video);
        navigate(`/how-it-works/${video.slug}`); // Update the URL slug
    };

    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div>
            <Header />
           
            <div className="container video-player-container pb-4">
                {
                currentVideo && <div className="main-video">
                     <h4 className="container pt-4 pb-2">{currentVideo && currentVideo.title}</h4>
                    <video controls autoPlay src={domain+currentVideo.video} className="main-video-element" />
                    <div className="main-video-info">
                        <h5 className="pt-2">Description</h5>
                        <p>{currentVideo.description}</p>
                        <small>{formatDate(new Date(currentVideo.created))}</small>
                    </div>
                </div>
                }

                {/* Video List Section */}
                <div className="video-list">
                    <h4 >Other Videos</h4>
                    <div className="video-thumbnails">
                        {data && data.map((video, index) => (
                            <div key={index} className="video-thumbnail" onClick={() => handleVideoChange(video)}>
                                <span className="text-left pb-2">{video.title}</span> 
                                <img src={domain+video.cover} alt={video.title} className="thumbnail-image" />
                                <figcaption className="thumbnail-title">
                                    <span className="video-duration text-muted">
                                        {durations[video.slug] || "Loading..."}
                                    </span>
                                </figcaption>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HowItWorksDetails