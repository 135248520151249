import React, {useState} from 'react'
import Header from './Header'
import Navigator from './Navigator'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { Card } from './Entry';
import "../css/EventDetails.css"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const CategorySelect = () => {
    return(
        <div>
            <span className='text-muted'>March 9, 2023, midnight</span>
            <h5 className='lead fs-6 fw-bolder pt-1 pb-1'>Please select a ticket Type</h5>
            <div className='d-flex justify-content-between border p-2 rounded'>
                <p>Tourism and Experience @ 120,000.0</p>
                <div className='d-flex align-items-center gap-2'>
                    <p>12 Tickets Left</p>
                    <Link to={'/ticket-purchase'} className='btn btn-success'>Select Category</Link>
                </div>
            </div>
        </div>
    )
}
const EventPreview = ({completed, regData, handleApprove}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div className='animate__animated animate__fadeIn'>
            <div className='details'>
                <div className='detailsIntroduction'>
                    <img className='w-100' src='https://img.freepik.com/free-photo/new-business-ribbon-cutting-celebration-event-concept_53876-124065.jpg?size=626&ext=jpg&ga=GA1.1.967060102.1696464000&semt=ais' />
                    <div>
                        <p>Food and Drink</p>
                        <h3>The Fabulous Brunch at Four Points by Sheraton</h3>
                        <p>Event By: </p>
                    </div>
                </div>
                <div className='row container pt-4 m-auto'>
                    <div className='col-lg-8'>
                        <div>
                            <h2 class="fs-5">About</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,</p>
                        </div>
                        <div>
                            <h2 class="fs-5">Age Restriction</h2>
                            <p>Below 18</p>
                            <h2 class="fs-5">Organiser</h2>
                            <p>Organiser Name here</p>
                            <h2 class="fs-5">Refund Policy</h2>
                            <p>refund policy here</p>
                            <div class="row">
                                <div class="col-lg-6">
                                    <h2 class="fs-5"><i class="fa fa-calendar" aria-hidden="true"></i> Date and Time</h2>
                                    <p>May 13, 2023, midnight</p>
                                </div>
                                <div class="col-lg-6">
                                    <h2 class="fs-5"><i class="fa-solid fa-location-dot"></i> Location</h2>
                                    <p>makerere - uganda</p>
                                </div>
                            </div>
                            <iframe
                            width="100%"
                            height="300px"
                            frameborder="0" style={{border:0}}
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50
                                &q=kampala,kampala university masaka campus" allowfullscreen>
                            </iframe>
                            <h2 class="fs-5">Share this Event</h2>
                            <a href="https://www.facebook.com/"><i class="fab fa-whatsapp text-success fs-3 pe-2"></i> </a>
                            <a href="https://www.facebook.com/"><i class="fab fa-facebook fs-3 pe-2"></i> </a>
                            <a href="https://www.instagram.com/"><i class="fab fa-instagram text-danger fs-3 pe-2"></i> </a>
                            <a href="https://twitter.com/"><i class="fab fa-twitter fs-2 pe-2"></i> </a>
                            
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="sticky">
                            <h2 className="fs-5">Ugx 20,000 /=</h2>
                            <hr />
                            <p><i className="fa fa-calendar icon" aria-hidden="true"></i> May 13, 2023, midnight</p>
                            <p><i className="fa fa-map-marker icon" aria-hidden="true"></i>  makerere - uganda </p>
                            <Link onClick={handleShow}>Proceed to Ticket Purchase</Link>
                            
                        </div>
        
                        {regData && <button disabled={completed} onClick={handleApprove} className='btn btn-danger mt-4'>publish Event</button>}
      
                    </div>
                </div>
            </div>
            <Modal 
                show={show} 
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bolder text-muted fs-5'>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CategorySelect />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary d-none" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EventPreview