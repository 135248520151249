// ActivePageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const FilterContext = createContext();

export const useFilterContext = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('FilterContext must be used within an LoadingProvider');
    }
    return context;
};

export const FilterProvider = ({ children }) => {
    /*
    const [categoryFilter, setCategoryFilter] = useState("")
    const [filterName, setFilterName] = useState("")
    const [eventDate, setEventDate] = useState("all")
    */

    const [categoryFilter, setCategoryFilter] = useState(() => {
        return localStorage.getItem('categoryFilter') || '';
    });
    const [filterName, setFilterName] = useState(() => {
        return localStorage.getItem('filterName') || '';
    });
    const [eventDate, setEventDate] = useState(() => {
        return localStorage.getItem('eventDate') || 'all';
    });

    useEffect(() => {
        localStorage.setItem('categoryFilter', categoryFilter);
    }, [categoryFilter]);

    useEffect(() => {
        localStorage.setItem('filterName', filterName);
    }, [filterName]);

    useEffect(() => {
        localStorage.setItem('eventDate', eventDate);
    }, [eventDate]);

    const value = {
        filterName,
        setFilterName,
        eventDate,
        setEventDate,
        categoryFilter, 
        setCategoryFilter
    };

    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};



/*
if at all need working with cookies as well
const [categoryFilter, setCategoryFilter] = useState(() => {
        return Cookies.get('categoryFilter') || '';
    });
    const [filterName, setFilterName] = useState(() => {
        return Cookies.get('filterName') || '';
    });
    const [eventDate, setEventDate] = useState(() => {
        return Cookies.get('eventDate') || 'all';
    });

    useEffect(() => {
        Cookies.set('categoryFilter', categoryFilter);
    }, [categoryFilter]);

    useEffect(() => {
        Cookies.set('filterName', filterName);
    }, [filterName]);

    useEffect(() => {
        Cookies.set('eventDate', eventDate);
}, [eventDate]);

 */