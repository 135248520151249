import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

// Higher-Order Component to check authentication
const withAuthCheck = (WrappedComponent, redirectPath = '/') => {
    return (props) => {
        const { authToken } = useContext(AuthContext); // Access authToken from context
        const navigate = useNavigate();

        useEffect(() => {
            // Check if authToken exists in local storage
            if (localStorage.getItem('authToken') || authToken) {
                navigate(redirectPath); // Redirect to the specified path
            }
        }, [authToken, navigate, redirectPath]);

        return <WrappedComponent {...props} />;
    };
};

export default withAuthCheck;