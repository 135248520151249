import React, { useContext, useState, useEffect } from 'react'
import "../css/Register.css"
import { Link, useNavigate } from 'react-router-dom';
import { domain } from './Domain'
import { AuthContext } from './AuthContext'
import useApiRequest from './useApiRequest'
import { motion, AnimatePresence } from 'framer-motion';
import notify from './Nots'
import withAuthCheck from './WithAuthCheck';


const Register = () => {

    const { postRequest, loading, setLoading } = useApiRequest()
    const { authToken, login, logout } = useContext(AuthContext);
    const initial = {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
        contact: '',
        profile: null
    }
    const [formData, setFormData] = useState(initial);
    const [isPasswordMatch, setIsPasswordMatch] = useState(true); // New state for password match
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const errors = validateForm(formData);
        setFormErrors(errors);
        setIsPasswordMatch(formData.password === formData.confirmPassword);
    }, [formData]);

    const validateForm = (data) => {
        let errors = {};
        for (let key in data) {
          if (data[key] === '') {
            errors[key] = `${key} is required`;
          }
        }
        if (data.password !== data.confirmPassword) {
          errors.confirmPassword = 'Passwords do not match';
        }
        return errors;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    //const isLoginFormValid = username.trim() !== '' && password.trim() !== '';  
    const navigate = useNavigate(); // Hook for navigation

    const handleRegister = async(e) => {
        e.preventDefault()
        //const errors = validateForm(formData);
        if (Object.keys(formErrors).length === 0) {
            setLoading(true)
            const formDatas = new FormData()
            for (const key in formData) {
                formDatas.append(key, formData[key]); 
            }
            const response = await postRequest(`${domain}/accounts/signup`, formDatas)
            if(response['status'] === "success"){
                notify(JSON.stringify(response['message']), "info")
            }else{
                notify(JSON.stringify(response['message']), "error")
            }
            //resetiing the form after submission
            setFormData(initial)
            e.target.reset()
        } else {
            //setFormErrors(errors);
            //create notifications for those fields that have no input
            for (const key in formErrors) {
                notify(formErrors[key], "error"); // Adjust the notify function as needed
            }
        }
        setLoading(false)
    }   
    return (
        <div className='regDiv animate__animated animate__fadeIn'>
            <form className='register' onSubmit={handleRegister}>
                <img src="images/logo.png" className="w-50 d-block text-center m-auto" alt="logo" />
                <h2 className="fw-bold text-center fs-5 pt-2 pb-2">Register to EventPlots</h2>
                <div>
                    <label className="form-label">Fullname</label>
                    <div className="input-group mb-1">
                        <span className="input-group-text"><i className="fa fa-list text-success" aria-hidden="true"></i></span>
                        <input type="text" aria-label="First name" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="firstname" className="form-control" />
                        <input type="text" aria-label="Last name" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="lastname" className="form-control" />
                    </div>
                    <label className="form-label">Username</label>
                    <div className="input-group mb-1">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-user text-success" aria-hidden="true"></i></span>
                        <input type="text" className="form-control" name="username" value={formData.username} onChange={handleChange} placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                    <label className="form-label">Phone number</label>
                    <div className="input-group mb-1">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-mobile text-success" aria-hidden="true"></i></span>
                        <input type="tel" name="contact" className="form-control" value={formData.contact} onChange={handleChange} placeholder="Phone number" aria-label="phone" aria-describedby="basic-addon1" />
                    </div>
                    <label className="form-label">Email</label>
                    <div className="input-group mb-1">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-envelope text-success" aria-hidden="true"></i></span>
                        <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} placeholder="Email" aria-label="Email" aria-describedby="basic-addon1" />
                    </div>
                    <label className="form-label">Password</label>
                    <div className="input-group">
                        <span className="input-group-text"><i class="fa fa-user-secret text-success" aria-hidden="true"></i></span>
                        <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="password" aria-label="password1" class="form-control" />
                        <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="confirm password" aria-label="password2" class="form-control" />
                    </div>
                    {!isPasswordMatch && <small className=' text-danger '>The passwords do not match</small>}
                    {loading ?
                        <button type='submit' class={`btn btn-success w-100 py-2 mt-4 mb-2`}  style={{ borderBottom: "5px solid var(--background)" }}>Submitting Registration Data ... <i class="fa-solid fa-spinner"></i></button>
                    :
                        <button type='submit' class={`btn btn-success w-100 py-2 mt-4 mb-2`}  style={{ borderBottom: "5px solid var(--background)" }}>Submit Registration Information <i class="fa-solid fa-right-to-bracket"></i></button>
                    }
                </div>
            </form>
        </div>
    )
}

export default Register