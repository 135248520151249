import { toast } from 'react-toastify';

const notify = (message, type) => {
    const toastType = {
        success: toast.success,
        error: toast.error,
        info: toast.info,
        warning: toast.warn,
    }[type] || toast.info; // Default to 'info' if type is not provided or invalid

    toastType(message, {
        position: 'bottom-right', // Position of the notification
        autoClose: 3000, // Time in milliseconds before the notification auto-closes
        hideProgressBar: false, // Show or hide the progress bar
        closeOnClick: true, // Close the notification when clicked
        pauseOnHover: true, // Pause auto-close on hover
        draggable: true, // Make the notification draggable
    });
};

export default notify;