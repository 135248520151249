/*
const events = [
    // Example event structure
    { id: 1, name: 'Event 1', start_date_time: '2023-10-16T10:00:00Z' },
    { id: 2, name: 'Event 2', start_date_time: '2023-10-18T10:00:00Z' },
    { id: 3, name: 'Event 3', start_date_time: '2023-10-20T10:00:00Z' }, // Friday
    { id: 4, name: 'Event 4', start_date_time: '2023-10-22T10:00:00Z' }, // Sunday
    { id: 5, name: 'Event 5', start_date_time: '2023-11-10T10:00:00Z' }, // Next month
];
*/

export const filterEvents = (filterType, events) => {
    console.log(events)
    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const todayEnd = new Date(todayStart);
    todayEnd.setDate(todayEnd.getDate() + 1); // End of today

    const weekStart = new Date(todayStart);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + (7 - weekEnd.getDay())); // End of the week (next Sunday)

    const weekendStart = new Date(todayStart);
    weekendStart.setDate(todayStart.getDate() + (5 - todayStart.getDay())); // Start from Friday
    const weekendEnd = new Date(weekendStart);
    weekendEnd.setDate(weekendEnd.getDate() + 2); // End of Sunday

    const monthStart = new Date(todayStart);
    const monthEnd = new Date(todayStart);
    monthEnd.setMonth(monthEnd.getMonth() + 1); // End of next month

    switch (filterType) {
        case 'today':
            return events.filter(event =>
                new Date(event.start_date_time) >= todayStart &&
                new Date(event.start_date_time) < todayEnd
            );

        case 'this week':
            return events.filter(event =>
                new Date(event.start_date_time) >= todayStart &&
                new Date(event.start_date_time) < weekEnd
            );

        case 'this weekend':
            return events.filter(event =>
                new Date(event.start_date_time) >= weekendStart &&
                new Date(event.start_date_time) < weekendEnd
            );

        case 'coming month':
            return events.filter(event =>
                new Date(event.start_date_time) >= monthStart &&
                new Date(event.start_date_time) < monthEnd
            );

        case 'online':
            return events.filter(event => JSON.parse(event.online_event) === true)

        default:
            return []
            //return events; // Return all events if no filter matches
    }
};

// Example usage:
/*
const todayEvents = filterEvents('today');
const weekEvents = filterEvents('this week');
const weekendEvents = filterEvents('this weekend');
const monthEvents = filterEvents('coming month');

console.log('Today\'s Events:', todayEvents);
console.log('This Week\'s Events:', weekEvents);
console.log('This Weekend\'s Events:', weekendEvents);
console.log('Coming Month\'s Events:', monthEvents);
*/
