// ActivePageContext.js
import React, { createContext, useContext, useState } from 'react';

const EventContext = createContext();

export const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('EventContext must be used within an EventProvider');
  }
  return context;
};

export const EventProvider = ({ children }) => {
  const [event, setEvent] = useState({
    event: "",
    eventName: "",
  });

  const value = {
    event,
    setEvent,
  };

  return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
};