import React, { useEffect, useRef, useState } from 'react'
import Header from './Header'
import { Link, useNavigate } from 'react-router-dom'
import "../css/Entry.css"
import Carousel from 'react-bootstrap/Carousel';
import useFetch from './useFetch'
import { domain } from './Domain'
import useApiRequest from './useApiRequest'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ugandaDistricts } from './Districts';
import { useFilterContext } from './FilterContext';
import { filterEvents } from './EventFilter';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export const Categories = () => {
    const navigate = useNavigate();
    const { categoryFilter, setCategoryFilter } = useFilterContext()
    const { data, loading, error, refresh, setRefresh } = useFetch(`${domain}/event-categories`);
    const array = new Array(3).fill(null)

    // useEffect to fetch data when component mounts and unmounts
    useEffect(() => {
        setRefresh(() => !refresh); // Fetch data when component mounts
        return () => {
            // Optional cleanup function if needed when component unmounts
        };
    }, []);

    const activeDiv = {
        boxShadow: "2px 2px rgb(243, 144, 108)",
        backgroundColor: "#495057",
        color: "#e9ecef"
    }

    const handleClick = () => {
        setCategoryFilter("");
        navigate(`/categories/all`);
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <h2 className='text-secondary fw-bolder fs-6'>Categories </h2>
                {categoryFilter != "" && <button className='btn btn-danger btn-sm' onClick={handleClick}>cancel selection</button>}
            </div>
            <div className='categories d-flex flex-wrap gap-2'>
                {loading &&
                    <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
                        {array.map((item, index) => (
                            <Skeleton className='flex-grow-1' key={index} height={30} width={100} count={2} />
                        ))}
                    </SkeletonTheme>
                }
                {data && data.map((category, index) => {
                    const isActive = categoryFilter === category.slug;
                    return (
                        <Link 
                            style={isActive ? activeDiv : {}} 
                            onClick={() => setCategoryFilter(category.slug)} // Handle click 
                            to={`/categories/${category.name.toLowerCase().replace(/\s+/g, '-')}`} 
                            className='flex-grow-1' 
                            key={index}>{category.name}</Link>
                    )
                })}
            </div>
        </>
    )
}

export const Filter = () => {
    const { eventDate, setEventDate } = useFilterContext()
    const handleClick = (e) => {
        setEventDate(e)
    }
    return (
        <>
            <h2 className='text-secondary fw-bolder fs-6 pt-4'>Filter Events</h2>
            <ul className="list-group filterEvents">
                <li className={`list-group-item text-white bg-success`} aria-current="true">Currently Viewing {eventDate}</li>
                <li className={`list-group-item ${eventDate === "all" ? "text-white bg-secondary" : ""}`} aria-current="true" onClick={() => handleClick("all")}>All Through</li>
                <li className={`list-group-item ${eventDate === "today" ? "text-white bg-secondary" : ""}`} onClick={() => handleClick("today")}>Today</li>
                <li className={`list-group-item ${eventDate === "this week" ? "text-white bg-secondary" : ""}`} onClick={() => handleClick("this week")}>This Week</li>
                <li className={`list-group-item ${eventDate === "this weekend" ? "text-white bg-secondary" : ""}`} onClick={() => handleClick("this weekend")}>Happening this weekend</li>
                <li className={`list-group-item ${eventDate === "coming month" ? "text-white bg-secondary" : ""}`} onClick={() => handleClick("coming month")}>Coming month Events</li>
                <li className={`list-group-item ${eventDate === "online" ? "text-white bg-secondary" : ""}`} onClick={() => handleClick("online")}>Online</li>
            </ul>
        </>
    )
}

export const FilterDistrict = () => {
    const { filterName, setFilterName } = useFilterContext()

    return (
        <>
            <h2 className='text-secondary fw-bolder fs-6 pt-4'>View by Distrct</h2>
            <select className='form-control' value={filterName} onChange={(e) => setFilterName(e.target.value)}>
                <optgroup label='select a district'>
                    <option selected value={""}>All Districts</option>
                    {ugandaDistricts && ugandaDistricts.map(item =>
                        <option key={item.id} value={item.name}>{item.name}</option>
                    )}
                </optgroup>
            </select>
        </>
    )
}

const CarouselImage = ({ img }) => {
    return (
        <>
            <img className='w-100' src={img} />
        </>
    )
}
const Slidder = () => {
    const { data, loading, setLoading } = useFetch(`${domain}/advertisements`)
    const truncateString = (str) => {
        return str.length > 90 ? str.substring(0, 89) + "..." : str;
    }
    return (
        <div className='col-lg-8 slidder'>
            <Carousel>
                { data && data.map((add, index) => 
                    <Carousel.Item key = {add.id} interval={index % 2 === 0 ? 2000 : 5000}>
                        <CarouselImage img={domain+add.banner} />
                        <Carousel.Caption>
                            <h3 className='text-white'>{add.event_plot_name}</h3>
                            <Link to={`/events/${add.slug}`} className='text-white'><p>{truncateString(add.event_description)}</p></Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                )}
            </Carousel>
        </div>
    )
}

const Feedback = ({ handleShow }) => {
    return (
        <div className="col-lg-4">
            <h1 className="fw-bold fs-5 lead fw-bold pt-2 pb-2" style={{ color: "darkcyan" }}><i className="fas fs-1 fa-comments" style={{ color: "darkcyan" }}></i> We appreciate your Feedback</h1>
            <p className="fs-6 pt-1 lead">We are commited to delivering a robust application to suit all your booking needs, please take some time and fill out the feedback form so as to enable us serve you better. <br /><span class="text-warning fw-bold">Thank you</span> </p>
            <Link className="btn btn-success" onClick={handleShow}>Submit A Review</Link>
        </div>
    )
}

export const Card = ({ image, title, slug, description, date, venue, organiser, category, views, likes }) => {
    const [showShareButtons, setShowShareButtons] = useState(false);

    const toggleShareButtons = () => {
        setShowShareButtons(!showShareButtons);
    }

    return (
        <div class="card p-0">
            <div className='cardImageDiv'>
                <img src={image} class="card-img-top" alt="..." />
            </div>
            <div class="card-body">
                <h3 class="fs-6 fw-normal"><i class="fa-solid fa-layer-group"></i> {category}</h3>
                <h3 class="fs-6 fw-bolder">{title}</h3>
                <p class="card-text"><Link to={`/events/${slug}`}>{description}</Link></p>
                <p class="text-danger mb-1"><i class="fa-solid fa-calendar-days"></i> {date} </p>
                <p class="text-muted mb-1"><i class="fa-solid fa-location-crosshairs"></i> {venue}</p>
                <p class="text-muted mb-1"><i class="fa-solid fa-people-roof"></i> Organiser: {organiser}</p>
                <hr />
                <div className='d-flex justify-content-evenly gap-2'>
                    <button className='flex-grow-1 btn btn-info text-white'><i class="fa-solid fa-heart"></i> {likes} Likes</button>
                    <button className='flex-grow-1 btn btn-warning text-white'><i class="fa-solid fa-eye"></i> {views} Views</button>
                    <button onClick={toggleShareButtons} className='flex-grow-1 btn btn-secondary'><i class="fa-solid fa-share-nodes"></i> Share</button>
                </div>
                {/* Share Buttons */}
                <div
                    className={`mt-3 text-center share-buttons ${showShareButtons ? 'show' : 'hide'}`}
                    style={{ transition: 'opacity 0.5s ease', opacity: showShareButtons ? 1 : 0, /*height: showShareButtons ? 'auto' : 0*/ }}
                >
                    <Link to="#" className="btn btn-whatsapp m-1"><i className="fa-brands fa-whatsapp"></i></Link>
                    <Link to="#" className="btn btn-twitter m-1"><i className="fa-brands fa-twitter"></i></Link>
                    <Link to="#" className="btn btn-linkedin m-1"><i className="fa-brands fa-linkedin"></i></Link>
                    <Link to="#" className="btn btn-primary m-1"><i class="fa-brands fa-facebook"></i></Link>
                </div>
            </div>
        </div>
    )
}
const Entry = () => {
    const { data, loading, setLoading } = useFetch(`${domain}/event-details`)
    const { postRequest, response, setResponse, error } = useApiRequest();
    const { filterName, setFilterName, eventDate, setEventDate } = useFilterContext()
    const [filteredData, setFilteredData] = useState(data);
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const truncateString = (str) => {
        return str.length > 90 ? str.substring(0, 89) + "..." : str;
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (filterName) {
            const filtered = data&&data.filter(event =>
                event.venue_name.toLowerCase().includes(filterName.toLowerCase()) ||
                event.venue_address.toLowerCase().includes(filterName.toLowerCase())
            );
            setFilteredData(filtered);

        } else {
            setFilteredData(data);
        }

    }, [data, filterName])
    

    useEffect(() => {
        if (filterName && data && data.length > 0) {
            const filtered = data.filter(event =>
                event.venue_name.toLowerCase().includes(filterName.toLowerCase()) ||
                event.venue_address.toLowerCase().includes(filterName.toLowerCase())
            );
            if(eventDate != "all" && data.length > 0){
                const filtered2 = filterEvents(eventDate, filtered)
                setFilteredData(filtered2);
            }else{
                setFilteredData(filtered);
            }
        } else {
            if(eventDate != "all" && data && data.length > 0){
                const filtered2 = filterEvents(eventDate, data)
                setFilteredData(filtered2);
            }else{
                setFilteredData(data);
            }
        }
    }, [data, filterName, eventDate])

    const handleFeedback = async(e) => {
        e.preventDefault()
        setFeedbackLoading(true)
        const formData = new FormData(e.target)
        const response = await postRequest(`${domain}/feedback`, formData)
        if(response){
            setFeedbackLoading(false)
            e.target.reset()
        }
    }


    /**
    {    useEffect(() => {
        if(eventDate != "all"){
            const filtered = filterEvents(eventDate, data)
            setFilteredData(filtered);
        }else{
            setFilteredData(data);
        }
    }, [eventDate])}
     */

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    return (
        <div>
            <div className='animate__animated animate__fadeIn'>
                <Header />
                <div className='d-flex gap-4 ps-4 pe-4 pt-4'>
                    <div className='w-25'>
                        <div className='w-100 side'>
                            <Categories />
                            <Filter />
                            <FilterDistrict />
                        </div>
                    </div>
                    <div className='w-75'>
                        <h3 className='lead fw-light w-100 text-secondary'>Looking for a place to hungout for the weekend or weekday, navigate the numerous events and have make your day count.</h3>
                        <div className='row'>
                            <Slidder />
                            <Feedback handleShow={handleShow} />
                        </div>
                        <h3 className='lead fw-light fw-bold pt-3 pb-2 text-secondary'>Current Events Happening on Our Platform {filterName != "" && <span className='text-success'> - {filterName}</span>}</h3>
                        <div className='d-flex flex-wrap gap-2'>
                            {filteredData && filteredData.length > 0 ? filteredData.map(item =>
                                <Card
                                    key={item.id}
                                    image={`${domain}${item.listing_img}`}
                                    category={item.event_category.name}
                                    title={item.event_plot_name}
                                    slug={item.slug}
                                    description={truncateString(item.event_description)}
                                    date={formatDate(new Date(item.start_date_time))}
                                    venue={item.venue_name}
                                    organiser={item.organiser.organiser_name}
                                    views = {item.views}
                                    likes = {item.likes}
                                />
                            ) :
                                <p>Ooops, no data found</p>
                            }

                        </div>
                    </div>
                </div>
                {/* modal */}
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-5 fw-bold'>Please submit Your Review</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleFeedback}>
                        <p className="fs-6 lead">Thank you for taking time to make this platform the best, we appreciate your responses as they help us serve you better</p>
                        <select name="reason" className="form-control" required>
                            <option value="" disabled selected>Reason for your feedback</option>
                            <option value="Website Navigation Experience">Website Navigation Experience</option>
                            <option value="Booking Experience">Booking Experience</option>
                        </select>
                        <label className="pt-2 pb-2 fs-6 text-muted">How do you rate your overall experience?</label><br />
                        <input type="radio" name="experience" required value="bad" /> Bad <br />
                        <input type="radio" name="experience" required value="average" /> Average <br />
                        <input type="radio" name="experience" required value="good" /> Good <br />
                        <input type="radio" name="experience" required value="verygood" /> Very Good <br />
                        <input type="radio" name="experience" required value="excellent" /> Excellent <br />
                        <label className="pt-2 pb-2 fs-6 text-muted">Comment</label><br />
                        <textarea name="comment" required className="form-control" rows="4" placeholder="please specify your comment"></textarea>
                        <div className="row m-auto g-2 pb-2">
                            <div className="col-6">
                                <input type="text" className="form-control" required name="name" placeholder="Enter your full name" />
                            </div>
                            <div className="col-6">
                                <input type="email" className="form-control" required name="email" placeholder="Enter your Email Address" />
                            </div>
                            <div className="col-12">
                                <input type="tel" className="form-control" required name="phone" placeholder="Enter your phone number" />
                            </div>
                        </div>
                        <button className="btn btn-success" type='submit'>{feedbackLoading ? 'Sending ' : 'Send '}Feedback <i className="fa-solid fa-paper-plane"></i></button>
                    </form>
                    </Modal.Body>
                    <Modal.Footer className='d-none'>
                        <Button variant="secondary" >
                            Close
                        </Button>
                        <Button variant="primary" >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default Entry



/*
    useEffect(() => {
        if (filterName) {
            const filtered = data.filter(event =>
                event.venue_name.toLowerCase().includes(filterName.toLowerCase()) ||
                event.venue_address.toLowerCase().includes(filterName.toLowerCase())
            );
            setFilteredData(filtered);
            
        } else {
            setFilteredData(data);
        }
    }, [data, filterName, eventDate])
*/