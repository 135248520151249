import React, { useState, useEffect } from 'react'
import Header from './Header'
import Navigator from './Navigator'
import "../css/Search.css"
import { Card } from './Entry'
import { useLocation } from 'react-router-dom';
import { domain } from './Domain';
import useApiRequest from './useApiRequest';

const Search = () => {
  const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Helper function to extract query parameter
  const getQuery = () => {
    return new URLSearchParams(location.search).get('q');
  };

  const truncateString = (str) => {
    return str.length > 90 ? str.substring(0, 89) + "..." : str;
  }

  // Function to format the date to 'August 3, 2023'
  function formatDate(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }

  useEffect(() => {
    const fetchData = async () => {
      const query = getQuery();
      if (query) {
        setLoading(true);
        try {
          const response = await getRequest(`${domain}/event-details?q=${getQuery()}`);
          setResults(response);
          //const response = await axios.get(`/api/search?q=${query}`);
          //setResults(response.data);
        } catch (error) {
          console.error('Error fetching search results', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [location.search]);


  return (
    <div className='animate__animated animate__fadeIn'>
      <Header />
      <div className='container search pt-4 pb-4'>
        {loading ? (
          <p>Loading...</p> // Loading indicator
        ) : (
          <div>
            <h2 className='fs-4 pb-3'>Search Results For {getQuery()}</h2>
            {results.length > 0 ? (
              <div className='d-flex flex-wrap gap-2'>
                {results.map(item =>
                  <Card
                    key={item.id}
                    image={`${domain}${item.listing_img}`}
                    category={item.event_category.name}
                    title={item.event_plot_name}
                    slug={item.slug}
                    description={truncateString(item.event_description)}
                    date={formatDate(new Date(item.start_date_time))}
                    venue={item.venue_name}
                    organiser={item.organiser.organiser_name}
                    views = {item.views}
                    likes = {item.likes}
                  />
                )}
              </div>
            ) : (
              <p>No results found.</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Search