export const ugandaDistricts = [
    { name: "Abim" },
    { name: "Adjumani" },
    { name: "Akiba" },
    { name: "Alebtong" },
    { name: "Amuria" },
    { name: "Amuru" },
    { name: "Apac" },
    { name: "Arua" },
    { name: "Budaka" },
    { name: "Bududa" },
    { name: "Bugiri" },
    { name: "Buhweju" },
    { name: "Buikwe" },
    { name: "Buliisa" },
    { name: "Bundibugyo" },
    { name: "Bushenyi" },
    { name: "Buyende" },
    { name: "Caloro" },
    { name: "Kamuli" },
    { name: "Kamwenge" },
    { name: "Kanungu" },
    { name: "Kapchorwa" },
    { name: "Kasese" },
    { name: "Katakwi" },
    { name: "Kayunga" },
    { name: "Kibaale" },
    { name: "Kiboga" },
    { name: "Kiryandongo" },
    { name: "Kisoro" },
    { name: "Kitgum" },
    { name: "Koboko" },
    { name: "Kole" },
    { name: "Kumi" },
    { name: "Kyenjojo" },
    { name: "Lira" },
    { name: "Luuka" },
    { name: "Luwero" },
    { name: "Lwengo" },
    { name: "Lyantonde" },
    { name: "Manafwa" },
    { name: "Maracha" },
    { name: "Masaka" },
    { name: "Masindi" },
    { name: "Mayuge" },
    { name: "Mbale" },
    { name: "Mbarara" },
    { name: "Mitooma" },
    { name: "Mityana" },
    { name: "Nakapiripirit" },
    { name: "Nakaseke" },
    { name: "Namayingo" },
    { name: "Namutumba" },
    { name: "Napak" },
    { name: "Nebbi" },
    { name: "Ngora" },
    { name: "Ntoroko" },
    { name: "Ntungamo" },
    { name: "Oyam" },
    { name: "Pader" },
    { name: "Rukungiri" },
    { name: "Serere" },
    { name: "Sheema" },
    { name: "Soroti" },
    { name: "Tororo" },
    { name: "Wakiso" },
    { name: "Yumbe" }
];
