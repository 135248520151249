import React, {useState} from 'react'
import Header from './Header'
import Contact from './Contact'
import AppHead from './AppHead'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
//import 'react-loading-skeleton/dist/skeleton.css'
import "../css/HelpCenter.css"

const Helps = ({cls, title,description}) => {
  return(
    <Link className='div text-dark' to={`/help-center/${title}`}>
      <div>
        <i className={cls}></i>
      </div>
      <div>
        <h2>{title}</h2>
        <p className='lead'>{description}</p>
      </div>
    </Link>
  )
}
const HelpCenter = () => {
  const [search, setSearch] = useState('')
  const helps = [
    {
      id: 1,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    },
    {
      id: 2,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    },
    {
      id: 3,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    },
    {
      id: 4,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    },
    {
      id: 4,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    },
    {
      id: 5,
      cls: "fa fa-search",
      title: "Accounts",
      description: "update your account information and manage subscriptions"
    }
  ]
  return (
    <div className='animate__animated animate__fadeIn'>
      <Header />
      <AppHead
        title="Help Center"
        description="Help Center EventPlots"
        imageUrl="http://localhost:3000/images/logo.png"
        url={window.location.hostname}
        twitterCardType="summary_large_image"
      />
      <div className='helpHeader'>
        <form>
          <label>How can we help you</label>
          <div className="search-input">
            <input
              type="search"
              placeholder="How can we help you ..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <i className='fa fa-search search-icon'></i>
          </div>
        </form>
      </div>
      <div className='actions'>
        {helps.map((item) => {
          return(
            <Helps 
              key={item.id} 
              cls={item.cls} 
              title={item.title} 
              description = {item.description} />
            )
        })}
      </div>
      <Contact />
    </div>
  )
}

export default HelpCenter
/*
  <div className='bg-light'>
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Popular Articles</button>
      </li>
      <li className="nav-item" role="presentation">
        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Recent Articles</button>
      </li>
    </ul>
    <div className="tab-content" id="myTabContent">
      <div className="tab-pane fade show active pb-4" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
        <ul>
          <li>Change of password</li>
          <li>password reset</li>
          <li>Boost my eventPlot</li>
        </ul>
      </div>
      <div className="tab-pane fade pb-4" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
        <ul>
          <li>Contact Us</li>
          <li>Enable SMS Notifications</li>
          <li>Enable Email Notifications</li>
        </ul>
      </div>
    </div>
  </div>
 */