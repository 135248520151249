import React, { useState } from 'react';
import '../css/Likes.css'; // Import the CSS for styling

const Likes = () => {
    const [liked, setLiked] = useState(false);

    const toggleLike = () => {
        setLiked(!liked);
    };

    return (
        <i onClick={toggleLike} className={`fa fa-heart heart ${liked ? 'active glowing' : ''}`} aria-hidden="true"></i>
    );
};

export default Likes;
