import React, { useState } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import notify from './Nots';
import '../css/ChangePassword.css'; // Optional: Create a CSS file for styling

const ChangePassword = () => {
    const { postRequest } = useApiRequest();
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setError(''); // Clear error on change
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword !== formData.confirmPassword) {
            setError("New password and confirm password do not match.");
            return;
        }

        try {
            const formDatas = new FormData()
            formDatas.append("old_password", formData.currentPassword)
            formDatas.append("new_password", formData.newPassword)
            formDatas.append("new_password_confirm", formData.confirmPassword)
            const response = await postRequest(`${domain}/accounts/change-password`, formDatas);

            if (response.message.includes('wrong!') || response.message.includes('provide user id,')) {
                notify(response['message'], "error");
            } else {
                notify(response.message, "info");
                setFormData({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                })
            }
        } catch (err) {
            console.error('Error changing password:', err);
            notify("An error occurred. Please try again later.", "error");
        }
    };

    return (
        <div className="change-password-container">
            <h3>Change Password</h3>
            <form onSubmit={handleSubmit} className="change-password-form">
                <div className="form-group">
                    <label>Current Password:</label>
                    <input
                        type="password"
                        name="currentPassword"
                        value={formData.currentPassword}
                        placeholder='current password'
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>New Password:</label>
                    <input
                        type="password"
                        name="newPassword"
                        value={formData.newPassword}
                        placeholder='New password'
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Confirm New Password:</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        placeholder='Confirm new password'
                        onChange={handleChange}
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="btn btn-primary">Change Password</button>
            </form>
        </div>
    );
};

export default ChangePassword;
