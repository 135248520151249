import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import '../css/MyEvents.css';
import { domain } from './Domain';
import useFetch from './useFetch';
import useApiRequest from './useApiRequest';
import { useNavigate } from 'react-router-dom';
import { useLoadingContext } from './LoadingContext';
import { saveToLocalStorage } from './EncryptDecrypt';

const EventList = ({ dataProfiles, events, onEdit, onDelete }) => {
    const truncateString = (str) => {
        if (str.length <= 90) return str;

        // Truncate the string to 90 characters
        let truncated = str.substring(0, 90);

        // Find the last space in the truncated string
        const lastSpaceIndex = truncated.lastIndexOf(' ');

        // If there's a space, truncate to that index; otherwise, keep it as is
        if (lastSpaceIndex > -1) {
            truncated = truncated.substring(0, lastSpaceIndex);
        }

        return truncated + " ...";
    };
    return (
        <div className="event-list">
            {dataProfiles && events && dataProfiles.map((event, index) => {
                const detailed = events.find(item => item.organiser.id === event.id);
                return (
                    detailed ? <div key={index} className="event-card">
                        <img src={domain + detailed.banner} alt={detailed.event_plot_name} className="event-cover" />
                        <h3 className="event-title">{detailed.event_plot_name}</h3>
                        <p className="event-description">{truncateString(detailed.event_description)}</p>
                        <p className="event-location">{detailed.venue_name}</p>
                        {/*<p className="event-price">${detailed.event_capacity}</p>*/}
                        {detailed.organiser.status ? (
                            <span className="event-status completed">Completed</span>
                        ) : (
                            <span className="event-status not-completed">Not Completed</span>
                        )}
                        <div className="event-actions">
                            <button onClick={() => onEdit(detailed)}>Edit</button>
                            <button onClick={() => onDelete(detailed.id)}>Delete</button>
                        </div>
                    </div>
                    :
                    <div key={index} className="missing-info event-card">
                        <img src="https://th.bing.com/th/id/R.db0c9ce9132f40034d95bcaa1e5341cf?rik=Vc7zQVdlXC3kbg&pid=ImgRaw&r=0&sres=1&sresct=1" alt={event.organiser_name} className="event-cover" />
                        <h3>No details found</h3>
                        {event.status ? (
                            <span className="event-status completed">Completed</span>
                        ) : (
                            <span className="event-status not-completed">Not Completed</span>
                        )}
                        <p className="event-description"><q>Important details for this event are missing. Please review and update the necessary information to ensure a better experience for everyone.</q></p>
                        <p className="event-location">Thank You</p>
                        <div className="event-actions">
                            <button onClick={() => onEdit(event)}>Edit</button>
                            <button onClick={() => onDelete(detailed.id)}>Delete</button>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const MyEvents = () => {
    const navigate = useNavigate()
    const { data: dataEvents, loading: loadingEvents, setLoading: setLoadingEvents } = useFetch(`${domain}/event-details`)
    const { data: dataProfiles, loading: loadingProfiles, setLoading: setLoadingProfiles } = useFetch(`${domain}/my-events`)
    const { loading, setLoading } = useLoadingContext()

    useEffect(() => {
        setLoading(loadingEvents)
    }, [loadingEvents])

    const handleEdit = async(event) => {
        console.log('Edit event:', event);
        // Implement edit logic here
        saveToLocalStorage("eventReg", event.slug);
        navigate("/create-plot")
    };

    const handleDelete = (id) => {
        //setEvents(events.filter(event => event.id !== id));
    };

    return (
        <div>
            <Header />
            <div className='container-lg'>
                <h2 className='fs-4 pt-4'>My Events</h2>

                {dataProfiles ?
                    <EventList dataProfiles={dataProfiles} events={dataEvents} onEdit={handleEdit} onDelete={handleDelete} />
                    :
                    <div>
                        <h1>Events are Loading ...</h1>
                        <p>kindly wait ...</p>
                    </div>
                }
            </div>
        </div>
    )
}



export default MyEvents