import React from "react";
import { useParams } from 'react-router-dom';
import Header from './Header'
import Contact from './Contact'
import AppHead from './AppHead'

const HelpCenterInfo = () => {
    let { name } = useParams();
    return(
        <div>
            <Header />
            <div className="container pt-4">
                <h3>Help Center Information on - {name} </h3>
                <p>EventPlots is an innovative platform designed to streamline the process of planning and executing events with efficiency and precision. This comprehensive guide aims to provide users with a step-by-step walkthrough, offering detailed insights into the functionalities and features of EventPlots. Whether you are a seasoned event organizer or a newcomer to the world of event planning, this guide will empower you to harness the full potential of EventPlots, allowing you to create, manage, and elevate your events seamlessly. From understanding the fundamental concepts to mastering advanced techniques, embark on a journey with this guide to unlock the full capabilities of EventPlots and enhance your event planning experience</p>
                <p>EventPlots is an innovative platform designed to streamline the process of planning and executing events with efficiency and precision. This comprehensive guide aims to provide users with a step-by-step walkthrough, offering detailed insights into the functionalities and features of EventPlots. Whether you are a seasoned event organizer or a newcomer to the world of event planning, this guide will empower you to harness the full potential of EventPlots, allowing you to create, manage, and elevate your events seamlessly. From understanding the fundamental concepts to mastering advanced techniques, embark on a journey with this guide to unlock the full capabilities of EventPlots and enhance your event planning experience</p>
            </div>
        </div>
    )
}

export default HelpCenterInfo