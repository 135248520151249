import React from 'react';
import { Helmet } from 'react-helmet';

const AppHead = ({ title, description, imageUrl, url, twitterCardType, favicon32, favicon16 }) => {
  return (
    <Helmet>
        {/* Basic Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window.location}/>

        {/* Open Graph (OG) Meta Tags for Facebook and Twitter */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
        <meta name="twitter:card" content={twitterCardType || "summary_large_image"} />

        {/* Favicon */}
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32 || "/favicon.ico"} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16 || "/favicon.ico"} />

        {
            /* Mobile Meta Tags 
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#ffffff" />
            */
        }

        {/* Other Meta Tags */}
        {
            /* Customize these based on your needs *
            <meta name="keywords" content="your,keywords,here" />
            <meta name="author" content="Your Name" />
            <meta name="robots" content="index, follow" />
            */
        }
    </Helmet>
  );
};

export default AppHead;
