// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { saveToLocalStorage, getFromLocalStorage } from './EncryptDecrypt';

export const AuthContext = createContext();

export const useAuthContext = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            // Retrieve token from storage on initial load
            const token = await getFromLocalStorage("authToken");
            if (token) {
                setAuthToken(token);
                console.log("this is the token from local storage my brother!")
                console.log(token)
                console.log(authToken)
            }
        };

        fetchToken();
    }, []);

    const login = (token) => {
        //localStorage.setItem('authToken', JSON.stringify(token));
        saveToLocalStorage("authToken", token)
        setAuthToken(token);
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setAuthToken(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};