import React, { useState, useEffect } from 'react'
import Header from './Header'
import { Filter, Categories, FilterDistrict, Card } from './Entry'
import { useLoadingContext } from './LoadingContext';
import { domain } from './Domain';
import useApiRequest from './useApiRequest';
import { useParams } from 'react-router-dom';
import { useFilterContext } from './FilterContext';
import { filterEvents } from './EventFilter';

const SingleCategory = () => {
    const { filterName, setFilterName, eventDate, setEventDate } = useFilterContext()
    let { name } = useParams();
    const { setLoading } = useLoadingContext()
    const [events, setEvents] = useState([])
    const [filteredData, setFilteredData] = useState(events);
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();

    const truncateString = (str) => {
        return str.length > 90 ? str.substring(0, 89) + "..." : str;
    }

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchCategories();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, [name]);


    useEffect(() => {
        if (filterName && events && events.length > 0) {
            const filtered = events.filter(event =>
                event.venue_name.toLowerCase().includes(filterName.toLowerCase()) ||
                event.venue_address.toLowerCase().includes(filterName.toLowerCase())
            );
            if(eventDate != "all" && events.length > 0){
                const filtered2 = filterEvents(eventDate, filtered)
                setFilteredData(filtered2);
            }else{
                setFilteredData(filtered);
            }
        } else {
            if(eventDate != "all" && events && events.length > 0){
                const filtered2 = filterEvents(eventDate, events)
                setFilteredData(filtered2);
            }else{
                setFilteredData(events);
            }
        }
    }, [events, filterName, eventDate])

    const fetchCategories = async () => {
        const response = await getRequest(`${domain}/event-details?category=${name}`);
        setEvents(response);
    };

    return (
        <div className='animate__animated animate__fadeIn'>
            <Header />
            <div className='d-flex gap-4 ps-4 pt-4 pe-4'>
                <div className='w-25'>
                    <div className='w-100 side'>
                        <Categories />
                        <Filter />
                        <FilterDistrict />
                    </div>
                </div>
                <div className='w-75 animate__animated animate__fadeInUp'>
                    <h3 className='lead fw-light w-75 text-secondary fw-bold' >Viewing {name.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} Category Events</h3>
                    <p className='lead fs-6 fw-light'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <div className='d-flex flex-wrap gap-2'>
                        {filteredData && filteredData.length > 0 && filteredData.map(item =>
                            <Card
                                key={item.id}
                                image={`${domain}${item.listing_img}`}
                                category={item.event_category.name}
                                title={item.event_plot_name}
                                slug={item.slug}
                                description={truncateString(item.event_description)}
                                date={formatDate(new Date(item.start_date_time))}
                                venue={item.venue_name}
                                organiser={item.organiser.organiser_name}
                                views = {item.views}
                                likes = {item.likes}
                            />
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleCategory

/*
    <Card
        image={'https://estudentshop.com/static/images/pc2.jpg'}
        title={'we the best'}
        description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
        date={'March 9, 2023, midnight'}
        venue={'makerere - uganda'}
        organiser={'Bam Events'}
        category={"movies"}
    />
*/