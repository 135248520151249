import React, {useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom'
import "../css/Header.css"
import Navigator from './Navigator'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';

const Header = () => {
  const { postRequest } = useApiRequest()
  const { authToken, logout } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
        // Redirect to the search results page
        navigate(`/search?q=${searchQuery}`);
        setSearchQuery("")
    }
  };

  const handleLogout = async() => {
    const response = await postRequest(`${domain}/accounts/logout`, {})
    if(response['message'] === "logout successfull"){
        logout()
        navigate("/")
    }
  }


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
      <div className={`header ${isSticky ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
        <div className={`pe-4 ps-4 pt-2 pb-2 d-flex justify-content-between align-items-center gap-2 ${isSticky ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
          <div className='d-flex gap-4 align-items-center headerInner'>
            <Link to={"/"}><img src='/images/logo.png' alt='logo' width={'auto'} height={'auto'}/></Link>
            <form onSubmit={handleSearch}>
              <input type = "search" value={searchQuery} placeholder='Search Events by Event Name or Location' onChange={(e) => setSearchQuery(e.target.value)} />
              <button type="submit" className='btn btn-secondary'><i class="fa-solid fa-magnifying-glass"></i></button>
            </form>
          </div>
          <div>
              <Link className='p-2' to={'/help-center'}>Help Center</Link>
              <Link className='p-2' to={'/how-it-works'}>How it works</Link>

              <Link className='p-2 btn btn-primary text-white btn-sm me-2' to={'/create-plot'}><i class="fa-solid fa-plus"></i> Create a Plot</Link>
              { authToken ? 
              <Link className='p-2 btn btn-success text-white btn-sm' onClick={toggleDropdown}>
                <i class="fa-solid fa-bars"></i> Menu
                <ul style={{display: isOpen ? "block":"none"}}>
                  <li><Link className='text-white fw-normal' to="/my-events"><i class="fa-solid fa-calendar-days"></i> My Events</Link></li>
                  <li><Link className='text-white fw-normal' to="/accounts/profile"><i class="fa-regular fa-id-badge"></i> My Profile</Link></li>
                  <li onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i> Logout</li>
                </ul>
              </Link>
              :
              <Link className='p-2 btn btn-success text-white btn-sm' to={'/login'}><i class="fa-solid fa-right-to-bracket"></i> Sign In</Link>
              }
          </div>
        </div>
        <div className='navItems'>
          <Navigator />
        </div>
    </div>
    
  )
}

export default Header